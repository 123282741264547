function ParticleStars() {


    const starDivs = Array.from({ length: 1000 }, (_, index) => index + 1);

    return (
        <div className="containerStars">
            {starDivs.map((index) => (
                <div key={index} className="star"></div>
            ))}
        </div>

    );
}
export default ParticleStars;
