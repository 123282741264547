function ParticleGlow() {



    return (
        <div className="ParticleGlow">
            <div className="glow1"></div>
            <div className="glow2"></div>
        </div>

    );
}
export default ParticleGlow;
