

function Book3() {
    return (
        <div key="slide3" className="slide1 slide3">
           
        <section className="PhotoArea3"></section>
        <section className="TextArea">
          <div>
          <div className="subtittle">Tome III</div>
          <div className="tittle">The Knights of Comet</div>
          <div className="line"></div>
         
          <div className="description">The Order of Comets is the final book in the trilogy of the Make My Golem Universe. Contact with beings from the fifth dimension has brought a new perspective to humanity. Will we finally overcome the golems and directly connect with beings from the fifth dimension? Discover the conclusion to this epic adventure</div>
          </div>

          <div>
          <div className="boxes">
            <div className="box">+16 <br/>years</div>
            <div className="box">192 <br/>pages</div>
            <div className="box">Eng <br/>and Pt-BR</div>
            <div className="box">23x16x1.8 <br/>cm</div>
          </div>
          
          <div className="ButtonsArea">
  
            <div className="buttonbookArea">
              <div className="price free3">FREE</div>
              <div className="buttonbook free3">AMAZON</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 22,10</div>
              <div className="buttonbook">AMERICANAS</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 34,90</div>
              <div className="buttonbook">DIGITAL BOOK</div>
            </div>
            </div>
          </div>
        </section>
      
      
    </div>
    );
  }
  
  export default Book3;
  