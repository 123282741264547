import React from 'react';

const SimpleCarousel = ({ slides, currentSlide, onNextSlide, onPrevSlide }) => {
  return (
    <div className="simple-carousel">
      <button onClick={onPrevSlide}></button>
      <div className="slide">{slides[currentSlide]}</div>
      <button className='buttonRight' onClick={onNextSlide}></button>
    </div>
  );
};

export default SimpleCarousel;
