

function Book2() {
    return (
        <div key="slide2" className="slide1 slide2">
           
        <section className="PhotoArea2"></section>
        <section className="TextArea">
          <div>
          <div className="subtittle">Tome II</div>
          <div className="tittle">The Traveler of Time</div>
          <div className="line"></div>
          
          <div className="description">The Time Traveler is the second tome of the 3-book Make My Golem Universe series. In this book, you follow the journey of May, an astronaut chosen by the Song Foundation to embark on a quest to trace the billions of interactions Sofia created with her dimensional leap.</div>
          </div>

          <div>
          <div className="boxes">
            <div className="box">+16 <br/>years</div>
            <div className="box">278 <br/>pages</div>
            <div className="box">Eng <br/>and Pt-BR</div>
            <div className="box">23x16x1.8 <br/>cm</div>
          </div>
          
          <div className="ButtonsArea">
  
            <div className="buttonbookArea">
              <div className="price free2">FREE</div>
              <div className="buttonbook free2">AMAZON</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 22,10</div>
              <div className="buttonbook">AMERICANAS</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 34,90</div>
              <div className="buttonbook">DIGITAL BOOK</div>
            </div>
  
          </div>
          </div>
        </section>
      
      
    </div>
    );
  }
  
  export default Book2;
  