import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink, Route, Routes } from 'react-router-dom';
import './App.scss';
import ProgressiveBackground from './ProgressiveBackground';

import ImageHd from './background-hd.jpg';
import ImageLow from './background-low.jpg';

import IconInstagram from './IconInstagram.svg';
import IconYoutube from './IconYoutube.svg';
import Stamp from './Stamp.png';
import logo from './logo.svg';
import ParticleFog from './Components/ParticleFog/ParticleFog';
import ParticleSnow from './Components/ParticleSnow/ParticleSnow';

import HistoryPage from './Pages/HistoryPage/HistoryPage';
import CommunityPage from './Pages/CommunityPage/CommunityPage';
import BooksPage from './Pages/BooksPage/BooksPage';
import GamePage from './Pages/GamePage/GamePage';
import ParticleStars from './Components/ParticleStars/ParticleStart';
import ParticleGlow from './Components/ParticleGlow/ParticleGlow';

function App() {

  //Troca o componente background baseado no tomo escolhido pelo usuário

  const [currentBackground, setCurrentBackground] = useState(0);

  const changeBackground = (backgroundNumber) => {
    setCurrentBackground(backgroundNumber);
  };

  let currentBackgroundComponent;

  if (currentBackground === 0) {
    currentBackgroundComponent = (
      <div>
        <ProgressiveBackground src={ImageHd} placeholder={ImageLow} />
        <ParticleFog />
        <ParticleSnow />
      </div>
    );
  } else if (currentBackground === 1) {
    currentBackgroundComponent = (
      <div>

        <div className="ProgressiveBackground2"></div>
        <div className='blur'><ParticleFog /></div>
        <div className='blur2'><ParticleSnow /></div>
        <div className='blur3'><ParticleSnow /></div>
        <div className='rainAll'></div>
        
      
      </div>
    );
  } else if (currentBackground === 2) {
    currentBackgroundComponent = (
      <div >
        <div className="ProgressiveBackground3"></div>
        <ParticleStars />
        <ParticleGlow />
       
      </div>
    );
  }



  return (
    <Router>
      <div className="App">
        <div className="background">{currentBackgroundComponent}</div>
        <div className="container">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="containerBlocks">
          <div className="Menu">
            <NavLink to="/" className="MenuItem" activeClassName="active">
              Home
            </NavLink>
            <NavLink to="/history" className="MenuItem" activeClassName="active">
              History
            </NavLink>
            <NavLink to="/community" className="MenuItem" activeClassName="active">
              Community
            </NavLink>
            <NavLink to="/books" className="MenuItem" activeClassName="active">
              Books
            </NavLink>
            <NavLink to="/game" className="MenuItem" activeClassName="active">
              Game
            </NavLink>
          </div>
          <Routes>
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/books" element={<BooksPage currentBackground={currentBackground} changeBackground={changeBackground} />} />
            <Route path="/game" element={<GamePage />} />
          </Routes>

          <div className="Footer">
            <div className="SocialMedia">
              <div className="IconsArea">
                <div className="SocialIcon">
                  <a href="https://instagram.com/makemygolem" target="_blank" rel="noopener noreferrer">
                    <img src={IconInstagram} alt="Instagram" />
                  </a>
                </div>
                <div className="SocialIcon">
                  <a href="https://www.youtube.com/@makemygolem6463" target="_blank" rel="noopener noreferrer">
                    <img src={IconYoutube} alt="Youtube" />
                  </a>
                </div>
                <div></div>
              </div>
            </div>
            <div className="CreditArea">
              <div className="CreditText">
              The MakeMyGolem franchise, its logo and all its information are the intellectual property of Doble Dice Studios and Eder Pedroso Gadioli. Only with permission granted can the material be used. All rights reserved to the distributor 2024 and the studio that produced and conceived the franchise. More information contact us.
              </div>
              <div className="stamp">
                <img src={Stamp} alt="Stamp" className="StampImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
