

function Book1() {
    return (
        <div key="slide1" className="slide1">
           
        <section className="PhotoArea1"></section>
        <section className="TextArea">
          <div>
          <div className="subtittle">Tome I</div>
          <div className="tittle">The Cartographer of Stones</div>
          <div className="line"></div>
          
          <div className="description">The Stone Cartographer is the first volume of the Make My Golem Universe trilogy. Here, we follow the fascinating journey of researcher Sofia, who is tasked with unraveling one of the great mysteries constraining our known universe: access to the fifth dimension. Discover how Sofia explored and applied this knowledge in this captivating narrative filled with mystery and profound reflections on the mysteries of the universe</div>
          </div>

          <div>
          <div className="boxes">
            <div className="box">+16 <br/>years</div>
            <div className="box">234 <br/>pages</div>
            <div className="box">Eng <br/>and Pt-BR</div>
            <div className="box">23x16x1.8 <br/>cm</div>
          </div>
          
          <div className="ButtonsArea">
  
            <div className="buttonbookArea">
              <div className="price free1">FREE</div>
              <div className="buttonbook free1">AMAZON</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 22,10</div>
              <div className="buttonbook">AMERICANAS</div>
            </div>
  
            <div className="buttonbookArea">
              <div className="price">R$ 34,90</div>
              <div className="buttonbook">DIGITAL BOOK</div>
            </div>
  
          </div>
          </div>
        </section>
      
      
    </div>
    );
  }
  
  export default Book1;
  