import React, { useState } from 'react';
import SimpleCarousel from '../../Components/SimpleCarousel/SimpleCarousel';
import Book1 from './Components/Book1/Book1';
import Book2 from './Components/Book2/Book2';
import Book3 from './Components/Book3/Book3';

function BooksPage({ currentBackground, changeBackground }) {

  //pego o Background Atual e posiciono o slide na posição correta
  const [currentSlide, setCurrentSlide] = useState(currentBackground);

  //controla os slides
  const onNextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
    changeBackground((currentSlide + 1) % slides.length);
  };

  const onPrevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    changeBackground((currentSlide - 1 + slides.length) % slides.length);
  };

  const slides = [
    <Book1 />,
    <Book2 />,
    <Book3 />,
  ];

  return (
    <div className="BooksPage">
      <SimpleCarousel
        slides={slides}
        currentSlide={currentSlide}
        onNextSlide={onNextSlide}
        onPrevSlide={onPrevSlide}
      />

    </div>
  );
}

export default BooksPage;
