function ParticleSnow() {
    const snowDivs = Array.from({ length: 200 }, (_, index) => index + 1);
  
    return (
      <div className="containerSnow">
        {snowDivs.map((index) => (
          <div key={index} className="snow"></div>
        ))}
      </div>
    );
  }
  export default ParticleSnow;
  